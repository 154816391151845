import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import {
  useObjectTypeFilter,
  useSeverityFilter,
  useCurrentProject,
  getFilterActive,
} from "hooks";
import type { IAnnotation } from "views/AnnotationTool/api";
import { reviewModes, workflowStatuses } from "constants/imageReview";
import useGetReviewAnnotations from "views/image/Review/hooks/getReviewAnnotations";
import Annotations from "views/image/Annotations";
import useImageNavigation from "views/image/Review/hooks/imageNavigation";
import { updateMatchingAnnotation } from "views/image/utils";
import * as api from "views/image/api";
import * as utils from "./utils";
import TruePositiveBbReview from "./TruePositiveBbReview";

const reviewModeId = reviewModes.TRUE_POSITIVE_BBS;

export default function TruePositiveBbReviewContainer() {
  const params = useParams();
  const imageId: number = parseInt(params.image);

  const [imageIds, setImageIds] = useState<number[]>([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAnnotations, setLoadingAnnotations] = useState(true);
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);
  const [preloadedAnnotations, setPreloadedAnnotations] = useState<{
    imageId?: number;
    annotations?: IAnnotation[];
  }>({});

  const { objectTypeFilter } = useObjectTypeFilter();
  const { severityFilter } = useSeverityFilter();
  const currentProject = useCurrentProject();
  const { getAnnotations } = useGetReviewAnnotations();

  const [searchParams] = useSearchParams();
  const filterActive = getFilterActive(searchParams);

  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
  } = useImageNavigation({
    imageIds,
    currentImage: imageId,
  });

  async function getImageIds() {
    try {
      setLoadingImages(true);
      setImageIds([]);

      const imageIds = await utils.getImageIds({
        projectId: currentProject.id,
      });

      if (imageIds.length) {
        setImageIds(imageIds);
      } else {
        toast.error("No (more) images to review found");
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "TruePositiveBbReviewContainer.getImageIds.request",
        originalException: error,
        data: {
          projectId: currentProject.id,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to get images to review");
    }
    setLoadingImages(false);
  }

  useEffect(() => {
    getImageIds();
  }, [filterActive]);

  function _getAnnotations(imageId) {
    return getAnnotations({
      imageId,
      reviewModeId,
      projectId: currentProject?.id,
      params: {
        severities: severityFilter.join(","),
        types: objectTypeFilter.join(","),
      },
    });
  }

  async function onImageLoad() {
    if (preloadedAnnotations?.imageId === imageId) {
      setAnnotations(preloadedAnnotations.annotations);
    } else {
      // Make sure to fetch current image annotation before preloading next image
      await refreshAnnotations();
    }
    await preloadAnnotations();
  }

  useEffect(() => {
    // We use currentIndex instead of imageId because we want to wait until imageIds has been loaded
    if (currentProject?.id && currentIndex > -1) {
      onImageLoad();
    }
  }, [currentProject?.id, currentIndex, objectTypeFilter, severityFilter]);

  async function refreshAnnotations() {
    setLoadingAnnotations(true);
    const annotations = await _getAnnotations(imageId);
    setAnnotations(annotations);
    setLoadingAnnotations(false);
  }

  async function preloadAnnotations() {
    const nextImageId = imageIds[currentIndex + 1];

    if (!nextImageId || preloadedAnnotations.imageId === nextImageId) {
      return;
    }
    const annotations = await _getAnnotations(nextImageId);

    setPreloadedAnnotations({
      imageId: nextImageId,
      annotations,
    });
  }

  function onImageReviewed() {
    if (currentIndex === imageIds.length - 1) {
      toast.info(
        "That was the final image in this review. Refresh browser to see if there are more images to review"
      );
    }
    navigateToNextImage();
  }

  async function approveAllAnnotations() {
    const ids = annotations.flatMap((annotation) => Number(annotation.type_id));

    await api.updateAnnotations({
      projectId: currentProject.id,
      ids,
      update: { workflow_status: workflowStatuses.SUPERVISOR_APPROVED },
      onSuccess: onImageReviewed,
    });
  }

  async function flagImageForReannotation() {
    await api.flagImageForReannotation({
      imageId,
      onSuccess: onImageReviewed,
    });
  }

  function onAnnotationTypeUpdate(args: {
    typeId: number;
    workflow_status?: number;
    type?: number;
    severity?: number;
  }) {
    const updatedAnnotations = updateMatchingAnnotation({
      annotations,
      ...args,
    });
    setAnnotations(updatedAnnotations);
  }

  return (
    <>
      <TruePositiveBbReview
        loading={loadingImages}
        currentImage={imageId}
        currentIndex={currentIndex}
        imageIds={imageIds}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        approveAllAnnotations={approveAllAnnotations}
        flagImageForReannotation={flagImageForReannotation}
      />
      {Boolean(imageIds.length) && (
        <Annotations
          loading={loadingAnnotations || loadingImages}
          reviewMode={reviewModeId}
          imageId={imageId}
          annotations={annotations}
          onAnnotationTypeUpdate={onAnnotationTypeUpdate}
        />
      )}
    </>
  );
}
