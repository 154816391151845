import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import NoReviewContainer from "./NoReviewContainer";

export default function NoReviewToggle() {
  const { refactorReviewTools } = useFlags();

  if (!refactorReviewTools) {
    return null;
  }

  return <NoReviewContainer />;
}
