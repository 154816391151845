import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import ImageCanvas, { IProps } from "./ImageCanvas";
import ImageCanvas2, { IProps as IProps2 } from "./ImageCanvas2";

type FeatureToggledImageCanvasProps = IProps | IProps2;

export default function FeatureToggledImageCanvas(
  props: FeatureToggledImageCanvasProps
) {
  const { refactorReviewTools } = useFlags();

  if (!refactorReviewTools) {
    return <ImageCanvas {...(props as IProps)} />;
  }
  return <ImageCanvas2 {...(props as IProps2)} />;
}
