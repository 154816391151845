import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getSeverityFilter(
  searchParams: URLSearchParams
): FilterType["SEVERITY"] {
  const severityParams = searchParams.get(FilterKey.SEVERITY);
  return severityParams ? severityParams.split(",").map(Number) : [];
}

export function useSeverityFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const severityFilter = useMemo(() => {
    return getSeverityFilter(searchParams);
  }, [searchParams]);

  function setSeverityFilter(severities: number[]) {
    if (severities.length === 0) {
      if (searchParams.has(FilterKey.SEVERITY))
        searchParams.delete(FilterKey.SEVERITY);
    } else {
      searchParams.set(FilterKey.SEVERITY, severities.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  }
  return { severityFilter, setSeverityFilter };
}
