import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { ThumbDown } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ObjectType } from "interfaces";
type Props = {
  objectType: ObjectType;
  regionID: number;
  workflowStatus: number;
  setMLReview: (
    image: number,
    regionID: number,
    regionType: number,
    action: string,
    severity_id?
  ) => void;
};

export default function RejectSelector({
  objectType,
  regionID,
  workflowStatus,
  setMLReview,
}: Props) {
  const params = useParams();
  const image = parseInt(params.image);
  return (
    <IconButton
      onClick={() => {
        if (workflowStatus === 4) {
          setMLReview(image, regionID, objectType.id, "request");
        } else {
          setMLReview(image, regionID, objectType.id, "deny");
        }
      }}
    >
      <ThumbDown htmlColor={workflowStatus === 4 ? "red" : "white"} />
    </IconButton>
  );
}
