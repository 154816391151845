import { useSelector } from "hooks";
import { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { IReviewObjectList } from "state/reducers/objectreducer";
import { getWrappedEntryInReviewList } from "views/image/utils";

interface IProps {
  unreviewed_workflow_status: number;
  tool_path: string;
  reviewObjects: IReviewObjectList;
}

export function useReviewControls({
  unreviewed_workflow_status,
  tool_path,
  reviewObjects,
}: IProps): [(index: number) => void, number] {
  const navigate = useNavigate();
  const isMountedRef = useRef(true);
  const location = useLocation();
  const params = useParams();

  const filteredAnnotations = useSelector(
    (state) => state.image.filteredAnnotations
  );
  const filteredAnnotationsImage = useSelector(
    (state) => state.image.filteredAnnotationsImage
  );
  const annotationsLoaded = useRef(null);
  const currentImage = parseInt(params.image);

  const goToImageByIndex = useCallback(
    (index: number) => {
      // Check if the component is still mounted before navigating
      if (isMountedRef.current) {
        const imageIdAtIndex = getWrappedEntryInReviewList(
          reviewObjects,
          index
        );

        if (imageIdAtIndex) {
          navigate(`../../${imageIdAtIndex}/${tool_path}${location.search}`);
        }
      }
    },
    // Need to include `reviewObjects` or else it won't be kept up to date when it's updated by a response.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search, reviewObjects]
  );

  let currentIndex = -1;
  if (reviewObjects?.imageIds?.length > 0) {
    currentIndex = reviewObjects.imageIds.findIndex(
      (image) => image === currentImage
    );

    // If this is still -1, we know we have a list of images & that we aren't in it.
    // Thus we can move to a valid one.
    if (currentIndex === -1) {
      goToImageByIndex(0);
    }
  }

  useEffect(() => {
    // Exists to make sure we only react to reviews on the current image not switching to another image.
    // This is required to make backtracking work.
    if (currentImage === annotationsLoaded.current) {
      const reviewFinished = filteredAnnotations
        .flatMap((o) => o.workflow_status)
        .every((w) => w !== unreviewed_workflow_status);

      if (reviewFinished) {
        goToImageByIndex(currentIndex + 1);
      }
    }
    // Delay any auto navigation until all the annotations have finished loading.
    // We can't assume that the annotations are loaded on the first update after switching image due to multiple requests and updates.
    if (currentImage === filteredAnnotationsImage) {
      setTimeout(() => {
        annotationsLoaded.current = currentImage;
      }, 500);
    }
  }, [filteredAnnotations, filteredAnnotationsImage, currentImage]);

  useEffect(() => {
    if (
      !currentImage ||
      !reviewObjects?.imageIds?.length ||
      currentImage !== reviewObjects.imageIds[reviewObjects.imageIds.length - 1]
    ) {
      return;
    }

    const pendingAnnotations = filteredAnnotations.some((annotation) =>
      annotation.workflow_status.some((status) => status === 1)
    );

    if (!pendingAnnotations) {
      toast.info(
        "That was the final image in this review. Refresh browser to see if there are more images to review"
      );
    }
  }, [filteredAnnotations]);

  return [goToImageByIndex, currentIndex];
}
