import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import FalsePositiveBbReview from "./FalsePositiveBbReview";
import FalsePositiveBbReview2 from "./V2";

export default function FeatureToggledFalsePositiveBbReview() {
  const { refactorReviewTools } = useFlags();

  if (!refactorReviewTools) {
    return <FalsePositiveBbReview />;
  }
  return <FalsePositiveBbReview2 />;
}
