import { connect } from "react-redux";
import Component from "./ThermalTool";
import { RootState } from "state/store";

interface IStateProps {
  minTemp: number;
  maxTemp: number;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    minTemp: state.imageViewer.thermalData.min,
    maxTemp: state.imageViewer.thermalData.max,
  };
};

const mapDispatchToProps = {};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
