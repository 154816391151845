import {
  CloseOutlined,
  Flag,
  ModeEditOutlineOutlined,
  RemoveDone,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Paper, Tooltip, Typography } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useObjectTypeFilter, useBbox } from "hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { reviewModes } from "constants/imageReview";
import ToolsContainer from "views/image/Review/ReviewTools/ToolsContainer";
import PrimaryAction from "views/image/Review/ReviewTools/PrimaryAction";
import IgnoreImage from "views/image/Review/ReviewTools/IgnoreImage";

interface IProps {
  currentImage: number;
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  approveAllAnnotations: () => void;
  flagImageForReannotation: () => void;
  loading: boolean;
}
export default function FalsePositiveBbReview(props: IProps) {
  const {
    currentImage,
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    approveAllAnnotations,
    flagImageForReannotation,
    loading,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  // Get data from redux
  const [searchParams] = useSearchParams();

  const { objectTypeFilter } = useObjectTypeFilter();

  const { showBoxes, hideBbox } = useBbox();

  const toggleBoundingBox = () => {
    hideBbox(showBoxes);
  };

  if (!imageIds) {
    return <></>;
  }

  const primaryActions = (
    <>
      <PrimaryAction
        color="green"
        hotkey="q"
        tooltipText="Verify objects as false positives"
        onClick={approveAllAnnotations}
        icon={<RemoveDone sx={{ color: "#ffff" }} />}
      />
      <PrimaryAction
        color="red"
        hotkey="w"
        tooltipText="Flag image for reannotation"
        onClick={flagImageForReannotation}
        icon={<Flag sx={{ color: "#ffff" }} />}
      />
      <IgnoreImage
        imageId={currentImage}
        reviewModeId={reviewModes.FALSE_POSITIVE_BBS}
        objectTypeIds={objectTypeFilter}
        onIgnore={navigateToNextImage}
      />
    </>
  );

  const secondaryActions = (
    <>
      <Tooltip
        title="Toggle visibility for bounding boxes. Does not change anything on the image"
        placement="bottom"
        arrow
      >
        <VisibilityOutlined
          sx={{ color: "#ffff" }}
          onClick={toggleBoundingBox}
        />
      </Tooltip>
      <Tooltip title="Open image editor" placement="bottom" arrow>
        <Link
          to={`annotate${location.search}`}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ModeEditOutlineOutlined sx={{ color: "#ffff" }} />
        </Link>
      </Tooltip>
      <Tooltip title="Exit review mode" placement="bottom" arrow>
        <CloseOutlined
          sx={{ color: "#ffff" }}
          onClick={() => {
            navigate(`..${location.search}`);
          }}
        />
      </Tooltip>
    </>
  );

  return (
    <RoleWrapper
      keyName="supervisorFalseReview"
      fallback={
        <Paper sx={{ p: 2 }}>
          <Typography>You do not have access to this feature</Typography>
        </Paper>
      }
    >
      <ToolsContainer
        loading={loading}
        currentIndex={currentIndex}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        imageIds={imageIds}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
    </RoleWrapper>
  );
}
