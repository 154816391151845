import React from "react";
import { connect } from "react-redux";
import { RootState } from "state/store";
import { useSearchParams } from "react-router-dom";
import Component from "./ThermalViewer";
interface IStateProps {
  minTemp: number;
  maxTemp: number;
  thermalData: number[];
  width: number;
  height: number;
}

interface IDispatchProps {}

interface IOwnProps {
  imagePosition;
}

const mapStateToProps = (state: RootState) => {
  return {
    minTemp: state.imageViewer.minTemp,
    maxTemp: state.imageViewer.maxTemp,
    thermalData: state.imageViewer.thermalData.temperature,
    width: state.imageViewer.thermalData.width,
    height: state.imageViewer.thermalData.height,
  };
};

function TemperatureInjector(props) {
  const [searchParams] = useSearchParams();
  const low = searchParams.has("low")
    ? parseFloat(searchParams.get("low"))
    : props.minTemp;
  const high = searchParams.has("high")
    ? parseFloat(searchParams.get("high"))
    : props.maxTemp;
  return <Component {...props} low={low} high={high} />;
}

const mapDispatchToProps = {};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(TemperatureInjector);
