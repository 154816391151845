import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { useSelector, useLanguage } from "hooks";
import { severities } from "constants/imageReview";

interface IProps {
  onClick: (severityId: number) => void;
  onClose: () => void;
  anchorEl: null | HTMLElement;
}
export default function SeveritiesMenu(props: IProps) {
  const { anchorEl, onClick, onClose } = props;

  const issueSeverities = useSelector((state) => state.objects.issueSeverities);
  const { language } = useLanguage();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {issueSeverities
        .filter((severity) => severity.id < severities.MACHINE_DETECTED)
        .map((severity) => (
          <MenuItem
            key={severity.id}
            onClick={() => {
              onClick(severity.id);
              onClose();
            }}
          >
            {language === "SE" ? severity.se_name : severity.en_name}
          </MenuItem>
        ))}
    </Menu>
  );
}
