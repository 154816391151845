import { connect } from "react-redux";
import ImageViewer from "./ObjectType";
import { RootState } from "state/store";
import { IssueCategory, IssueSeverity, ObjectType } from "interfaces";

interface IDispatchProps {}

interface IStateProps {
  issueCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  objectTypes: ObjectType[];
}

interface IOwnProps {
  regionID: number;
  type: number;
  skyqraft_hidden: boolean;
  workflow_status: number;
}

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  return {
    issueCategories: state.objects.issueCategories,
    issueSeverities: state.objects.issueSeverities,
    objectTypes: state.objects.objectTypes,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ImageViewer);
