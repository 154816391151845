import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useRafState } from "react-use";
import { useSelector } from "hooks";
import { resetThermalData, setThermalData } from "state/actions";
import store from "state/store";
import { useEventCallback } from "utils";
import { authorizedGet } from "utils/request";
import { CanvasContext } from "views/AnnotationTool/provider";
import ThermalViewer from "views/ThermalViewer";
import CanvasContainer from "./CanvasContainer";
import SpeedImage from "./SpeedImage";
import "./style.scss";

export interface IProps {
  onLoad: () => void;
  widthScale: number;
}

export const ImageCanvas = function ImageCanvas({
  onLoad,
  widthScale,
}: IProps) {
  const customerId = useSelector((state) => state.mission?.id);
  const thermalData = useSelector((state) => state.imageViewer.thermalData);
  const cdn = useSelector((state) => state.image?.current?.cdn);
  const imageId = useSelector((state) => state.image?.current?.id);
  const reviewLoading = useSelector((state) => state.objects.reviewLoading);

  const params = useParams();
  const location = useLocation();
  const isThermal = location.pathname.includes("/thermal");
  const image = params.image;

  const [pipes, setPipes] = useState([]);

  const [loading, setLoading] = useRafState(true);

  const {
    matrix,
    imageDimensions,
    changeImageDimensions,
    setMatrix,
    resetMatrix,
    dragged,
    setCanvasWidth,
    setCanvasHeight,
    imageTopLeft,
    imageBottomRight,
    imageLoaded,
    canvasRef,
  } = useContext(CanvasContext);

  useEffect(() => {
    if (!loading && !!image && !!customerId) {
      authorizedGet<{ pipes }>(`/image/${image}/pipes`)
        .then(({ pipes }) => setPipes(pipes))
        .catch(() => setPipes([]));
      if (isThermal) {
        authorizedGet(`/image/${image}/thermal`)
          .then((response) => {
            //check if the response is a json object, otherwise try to parse it with JSON.parse
            const isJson = typeof response === "object";
            const responseJson = isJson ? response : JSON.parse(response);
            if (responseJson?.temperature?.length > 10) {
              store.dispatch(setThermalData(responseJson));
            }
          })
          .catch(() => resetThermalData());
      } else {
        resetThermalData();
      }
    }
  }, [image, loading, customerId, isThermal]);

  useEffect(() => {
    changeImageDimensions({
      naturalWidth: undefined,
      naturalHeight: undefined,
    });
  }, [image, setLoading]);

  const onVideoOrImageLoaded = useEventCallback((image) => {
    if (image.naturalHeight !== 10) {
      onLoad();
      setLoading(false);
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;
      const duration = image.duration;
      const dims = {
        naturalWidth: naturalWidth,
        naturalHeight: naturalHeight,
        duration: duration,
      };

      // NOTABLE: If the image fails to load, dims default to 512x512.
      changeImageDimensions(dims);
    }
  });

  const [clientWidthBefore, setClientWidthBefore] = useState(0);
  const [widthScaleBefore, setWidthScaleBefore] = useState(0);

  // Rescale based on:
  // - Window Width: When the browser window is resized, this indirectly changes the size of the view.
  // - widthScale: When the image viewer is resized directly.
  useEffect(() => {
    const newClientWidth = window.innerWidth;
    const difference = Math.abs(clientWidthBefore - newClientWidth);

    // Sometimes the window randomly changes by a few pixels.
    // Don't know why but here we ignore those jumps whilst still allowing regular drag resizing to function.
    if (
      (difference > 10 || widthScaleBefore !== widthScale) &&
      imageDimensions.naturalWidth
    ) {
      resetMatrix();
      setClientWidthBefore(newClientWidth);
      setWidthScaleBefore(widthScale);
    }
  }, [
    imageDimensions.naturalWidth,
    canvasRef.current,
    clientWidthBefore,
    setMatrix,
    widthScale,
    widthScaleBefore,
    window.innerWidth,
  ]);

  const canvas = canvasRef.current;
  let clientWidth = 900;

  if (canvas && imageLoaded) {
    clientWidth = canvas.clientWidth;
    const clientHeight = canvas.clientHeight;
    setCanvasWidth(clientWidth);
    setCanvasHeight(clientHeight);

    if (canvas.getContext && pipes.length > 1) {
      // Sets the canvas rendering resolution NOT the size of the canvas on screen.
      canvas.width = clientWidth;
      canvas.height = clientHeight;

      // Get the drawing mechanism in order
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 1 / matrix.a;
      ctx.strokeStyle = `rgba(255,0,0,${matrix.a})`; // Transparency follows zoom
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Compute top left and size
      const size = {
        w: clientWidth / matrix.a,
        h:
          (imageDimensions.naturalHeight * clientWidth) /
          imageDimensions.naturalWidth /
          matrix.a,
      };

      ctx.beginPath();

      ctx.moveTo(
        imageTopLeft.x + pipes[0][0] * size.w,
        imageTopLeft.y + pipes[0][1] * size.h
      );
      // Draw along line
      for (let line_number = 1; line_number < pipes.length; line_number++) {
        ctx.lineTo(
          imageTopLeft.x + pipes[line_number][0] * size.w,
          imageTopLeft.y + pipes[line_number][1] * size.h
        );
      }

      ctx.stroke();
    } else {
      // Clear if there is no pipeline
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  return (
    <>
      <CanvasContainer
        dragging={dragged}
        mat={matrix}
        imageLoaded={imageLoaded}
        reviewLoading={reviewLoading}
      >
        <Outlet />

        <canvas
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2,
            touchAction: "none",
          }}
          id="imageCanvas"
          ref={canvasRef}
        />

        {!!imageId && (
          <SpeedImage
            imagePosition={{
              topLeft: imageTopLeft,
              bottomRight: imageBottomRight,
            }}
            onLoad={onVideoOrImageLoaded}
            cdn={cdn}
            canvas={canvasRef.current}
          />
        )}
        {isThermal && thermalData.temperature.length > 10 && (
          <ThermalViewer
            imagePosition={{
              topLeft: imageTopLeft,
              bottomRight: imageBottomRight,
            }}
          />
        )}
      </CanvasContainer>
    </>
  );
};
export default ImageCanvas;
