import React from "react";
import { IconButton } from "@mui/material";
import { ThumbDown } from "@mui/icons-material";
import { severities, workflowStatuses } from "constants/imageReview";
import { annotationActions } from "../constants";

interface IProps {
  workflowStatus: number;
  updateAnnotation: (args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
    severity?: (typeof severities)[keyof typeof severities];
  }) => void;
}
export default function RejectSelector(props: IProps) {
  const { workflowStatus, updateAnnotation } = props;

  const isRequestAction = workflowStatus === workflowStatuses.FALSE_POSITIVE;

  return (
    <IconButton
      onClick={() => {
        if (isRequestAction) {
          updateAnnotation({
            action: annotationActions.REQUEST,
          });
        } else {
          updateAnnotation({
            action: annotationActions.DENY,
          });
        }
      }}
    >
      <ThumbDown htmlColor={isRequestAction ? "red" : "white"} />
    </IconButton>
  );
}
