import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import TruePositiveBbReview from "./TruePositiveBbReview";
import TruePositiveBbReview2 from "./V2";

export default function FeatureToggledTruePositiveBbReview() {
  const { refactorReviewTools } = useFlags();

  if (!refactorReviewTools) {
    return <TruePositiveBbReview />;
  }
  return <TruePositiveBbReview2 />;
}
