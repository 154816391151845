import {
  CloseOutlined,
  Done,
  Edit,
  MotionPhotosOffOutlined,
  Replay,
  ThumbDownAlt,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useBbox } from "hooks";
import ToolsContainer from "views/image/Review/ReviewTools/ToolsContainer";
import PrimaryAction from "views/image/Review/ReviewTools/PrimaryAction";
import { annotationActions } from "./constants";
import OrderByDropDown from "./OrderByDropDown";

interface IProps {
  currentIndex: number;
  navigateToNextImage: () => void;
  navigateToPrevImage: () => void;
  goToImageByIndex: (index: number) => void;
  imageIds: number[];
  setOrderImagesBy: (order: string) => void;
  updateAllNonDefectAnnotations: (args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
  }) => void;
  refreshAnnotations: () => void;
  hasAnyDefect: boolean;
  loading: boolean;
}
export default function MachineReview(props: IProps) {
  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    imageIds,
    setOrderImagesBy,
    updateAllNonDefectAnnotations,
    refreshAnnotations,
    hasAnyDefect,
    loading,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { showBoxes, hideBbox } = useBbox();

  const toggleBoundingBox = () => {
    hideBbox(showBoxes);
  };

  if (!imageIds) {
    return <></>;
  }

  const primaryActions = (
    <>
      <PrimaryAction
        color="lightGreen"
        hotkey="q"
        tooltipText={hasAnyDefect ? "Unavailable for defects" : "Approve all"}
        disabled={hasAnyDefect}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.APPROVE,
          });
        }}
        icon={<Done sx={{ color: "#ffff" }} />}
      />
      <PrimaryAction
        color="yellow"
        hotkey="w"
        tooltipText={
          hasAnyDefect ? "Unavailable for defects" : "Approve but hide all"
        }
        disabled={hasAnyDefect}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.APPROVE_HIDE,
          });
        }}
        icon={<MotionPhotosOffOutlined sx={{ color: "#ffff" }} />}
      />
      <PrimaryAction
        color="red"
        hotkey="e"
        tooltipText={
          hasAnyDefect ? "Unavailable for defects" : "Set all as false positive"
        }
        disabled={hasAnyDefect}
        onClick={() => {
          updateAllNonDefectAnnotations({
            action: annotationActions.DENY,
          });
        }}
        icon={<ThumbDownAlt sx={{ color: "#ffff" }} />}
      />
    </>
  );

  const secondaryActions = (
    <>
      <Tooltip
        title="Toggle visibility for bounding boxes. Does not change anything on the image"
        placement="bottom"
        arrow
      >
        <VisibilityOutlined
          sx={{ color: "#ffff" }}
          onClick={toggleBoundingBox}
        />
      </Tooltip>
      <Tooltip title="Refresh annotations with changes from database">
        <Replay sx={{ color: "#ffff" }} onClick={refreshAnnotations} />
      </Tooltip>
      <Tooltip title="Open image editor in new tab" placement="bottom" arrow>
        <Link
          to={`annotate${location.search}`}
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Edit sx={{ color: "#ffff" }} />
        </Link>
      </Tooltip>
      <OrderByDropDown setSelectedEntry={setOrderImagesBy} />
      <Tooltip title="Exit review mode" placement="bottom" arrow>
        <CloseOutlined
          sx={{ color: "#ffff" }}
          onClick={() => {
            navigate(`..${location.search}`);
          }}
        />
      </Tooltip>
    </>
  );

  return (
    <ToolsContainer
      loading={loading}
      currentIndex={currentIndex}
      navigateToNextImage={navigateToNextImage}
      navigateToPrevImage={navigateToPrevImage}
      goToImageByIndex={goToImageByIndex}
      imageIds={imageIds}
      primaryActions={primaryActions}
      secondaryActions={secondaryActions}
    />
  );
}
