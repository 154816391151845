import { connect } from "react-redux";
import Component from "./ApproveSelector";
import { RootState } from "state/store";
import { setMLReview } from "state/actions";
import { ObjectType, IssueSeverity, IssueCategory } from "interfaces";

interface IDispatchProps {
  setMLReview: (
    image: number,
    regionID: number,
    regionType: number,
    action: string,
    severity_id?
  ) => void;
}

interface IStateProps {
  issueSeverities: IssueSeverity[];
  issueCategories: IssueCategory[];
}

interface IOwnProps {
  objectType: ObjectType;
  regionID: number;
  skyqraft_hidden: boolean;
  workflowStatus: number;
}

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  return {
    issueCategories: state.objects.issueCategories,
    issueSeverities: state.objects.issueSeverities,
  };
};

const mapDispatchToProps = {
  setMLReview,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
