import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import IgnoreImage, { Props } from "./IgnoreImage";

export default function FeatureToggledIgnoreImage(props: Props) {
  const { ignoreImage } = useFlags();

  if (!ignoreImage) {
    return null;
  }
  return <IgnoreImage {...props} />;
}
