import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { severities } from "AppConstants";
import { useDispatch, useTranslation } from "hooks";
import { useState } from "react";
import { updateAnnotationsTypeData } from "state/actions";
import { getClientSeverityName } from "utils/utils";

type Props = {
  type_id: number;
  image_id: number;
  onClose: () => void;
};

export default function SetTypeSeverityDialog({
  type_id,
  image_id,
  onClose,
}: Props) {
  const dispatch = useDispatch();
  const confirmText = useTranslation("Confirm");
  const setSeverityForIssueText = useTranslation("SetSeverityForIssue");

  const [selectedSeverity, setSelectedSeverity] = useState(severities.ISSUE);

  const display_severities = [
    severities.CRITICAL,
    severities.ISSUE,
    severities.SHOULD_BE_FIXED,
    severities.GOOD_TO_KNOW,
  ];

  const onDialogClose = () => {
    // Submit Data.
    dispatch(
      updateAnnotationsTypeData(image_id, type_id, {
        severity: selectedSeverity,
      })
    );

    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={onDialogClose}
      data-test-id="settypeseveritydialog.dialog"
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();

          onDialogClose();
        },
      }}
    >
      <DialogTitle>{setSeverityForIssueText}</DialogTitle>
      <div className="dialogFlex">
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <Select
              labelId="select-severity-label"
              id="select-severity"
              value={selectedSeverity}
              onChange={(event) =>
                setSelectedSeverity(Number(event.target.value))
              }
            >
              {display_severities.map((severity) => (
                <MenuItem value={severity}>
                  {getClientSeverityName(severity).name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" data-testid="submit-set-type-severity">
            {confirmText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
