import React, { Component } from "react";
import colormap from "colormap";
import { COLOR_OPTIONS } from "./constants";
import "./style.scss";

interface Props {
  thermalData: number[];
  width: number;
  height: number;
  minTemp: number;
  maxTemp: number;
  imagePosition;
  low: number;
  high: number;
}
interface State {
  colors: number[][];
  moving: null | string;
  colorIndex: number;
}

export default class ThermalViewer extends Component<Props, State> {
  myRef;
  colorRef;

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.colorRef = React.createRef();
    this.state = {
      colors: colormap({
        colormap: "temperature",
        nshades: 50,
        format: "rba",
        alpha: 1,
      }),
      moving: null,
      colorIndex: 0,
    };
  }

  componentDidMount(): void {
    this.updateImage();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      prevProps.low !== this.props.low ||
      prevProps.high !== this.props.high
    ) {
      this.updateImage();
    }
    if (prevState.colorIndex !== this.state.colorIndex) {
      this.updateColors();
    }
  }

  updateColors = () => {
    this.setState(
      {
        colors: colormap({
          colormap: COLOR_OPTIONS[this.state.colorIndex],
          nshades: 50,
          format: "rba",
          alpha: 1,
        }),
      },
      this.updateImage
    );
  };

  updateImage = () => {
    const { thermalData } = this.props;

    const canvas = this.myRef.current;
    canvas.width = this.props.width;
    canvas.height = this.props.height;
    const ctx = canvas.getContext("2d");

    // get current raster data
    const imageData = ctx.getImageData(
      0,
      0,
      this.props.width,
      this.props.height
    );

    for (let i = 0; i < thermalData.length; i += 1) {
      const { red, green, blue } = this.makeHeat(thermalData[i]);
      imageData.data[i * 4] = red;
      imageData.data[i * 4 + 1] = green;
      imageData.data[i * 4 + 2] = blue;
      imageData.data[i * 4 + 3] = 255;
    }

    // draw newly modified pixels back onto the canvas
    ctx.putImageData(imageData, 0, 0);
  };

  makeHeat(temperature) {
    let percentage =
      (temperature - (this.props.low || this.props.minTemp)) /
      ((this.props.high || this.props.maxTemp) -
        (this.props.low || this.props.minTemp));
    const percentage_steps = 1 / this.state.colors.length;
    percentage = Math.max(Math.min(percentage, 1), 0);
    let upper_color = Math.round(
      (percentage - (percentage % percentage_steps)) / percentage_steps
    );
    let lower_color = Math.round(
      (percentage - (percentage % percentage_steps) + percentage_steps) /
        percentage_steps
    );

    if (lower_color < 0 || upper_color < 0) {
      lower_color++;
      upper_color++;
    }

    if (
      lower_color >= this.state.colors.length ||
      upper_color >= this.state.colors.length
    ) {
      lower_color--;
      upper_color--;
    }

    const bilnear_movement = (percentage % percentage_steps) / percentage_steps;
    const red =
      this.state.colors[lower_color][0] +
      (this.state.colors[upper_color][0] - this.state.colors[lower_color][0]) *
        bilnear_movement;
    const green =
      this.state.colors[lower_color][1] +
      (this.state.colors[upper_color][1] - this.state.colors[lower_color][1]) *
        bilnear_movement;
    const blue =
      this.state.colors[lower_color][2] +
      (this.state.colors[upper_color][2] - this.state.colors[lower_color][2]) *
        bilnear_movement;

    return { red, green, blue };
  }

  render() {
    const colors = [...this.state.colors];
    colors.reverse();
    let normalSize = this.props.height / colors.length;
    let n_partitions_left = colors.length;

    const upper_height =
      ((this.props.maxTemp - (this.props.high || this.props.maxTemp)) /
        (this.props.maxTemp - this.props.minTemp)) *
      this.props.height;

    const lower_height =
      (((this.props.low || this.props.minTemp) - this.props.minTemp) /
        (this.props.maxTemp - this.props.minTemp)) *
      this.props.height;

    let remaining_height = this.props.height;

    if (upper_height > normalSize) {
      n_partitions_left -= 1;
      remaining_height -= upper_height;
    }

    if (lower_height > normalSize) {
      n_partitions_left -= 1;
      remaining_height -= lower_height;
    }

    if (n_partitions_left < colors.length) {
      normalSize = remaining_height / n_partitions_left;
    }
    const width =
      this.props.imagePosition.bottomRight.x -
      this.props.imagePosition.topLeft.x;
    const height =
      this.props.imagePosition.bottomRight.y -
      this.props.imagePosition.topLeft.y;
    const stylePosition = {
      left: this.props.imagePosition.topLeft.x || 0,
      top: this.props.imagePosition.topLeft.y || 0,
      width: Number.isNaN(width) ? 0 : width,
      height: Number.isNaN(height) ? 0 : height,
    };
    return (
      <div id="thermalContainer">
        <div id="thermal" style={{ height: this.props.height }}>
          <canvas
            style={{
              ...stylePosition,
              zIndex: 0,
              position: "absolute",
            }}
            ref={this.myRef}
          />
        </div>
      </div>
    );
  }
}
