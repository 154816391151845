import { IconButton } from "@mui/material";
import { RemoveDone } from "@mui/icons-material";
import type { IAnnotation } from "views/AnnotationTool/api";
import { workflowStatuses } from "constants/imageReview";

const inactiveColor = "#0e7d21";
const activeColor = "#14b72f";

interface IProps {
  typeId: number;
  approveAnnotation: (id: number) => void;
  annotation: IAnnotation;
}
export default function AnnotationReviewButtons(props: IProps) {
  const { typeId, approveAnnotation, annotation } = props;

  const typeIdIndex = annotation.type_id.findIndex((id) => id === typeId);

  const workflowStatus = annotation.workflow_status[typeIdIndex];
  const active = workflowStatus === workflowStatuses.SUPERVISOR_FALSE_POSITIVE;

  return (
    <div>
      <IconButton
        sx={{
          backgroundColor: active ? activeColor : inactiveColor,
          "&:hover": {
            backgroundColor: activeColor,
          },
          cursor: active ? "default" : "pointer",
        }}
        onClick={() => approveAnnotation(typeId)}
      >
        <RemoveDone htmlColor="white" />
      </IconButton>
    </div>
  );
}
