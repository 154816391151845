import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import MachineReview from "./MachineReview";
import MachineReview2 from "./V2";

export default function FeatureToggledMachineReview() {
  const { refactorReviewTools } = useFlags();

  if (!refactorReviewTools) {
    return <MachineReview />;
  }
  return <MachineReview2 />;
}
