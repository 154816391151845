import React from "react";
import type { IAnnotation } from "views/AnnotationTool/api";
import { severities } from "constants/imageReview";
import { annotationActions } from "../constants";
import ApproveSelector from "./ApproveSelector";
import HideSelector from "./HideSelector";
import RejectSelector from "./RejectSelector";
import "./style.scss";

interface IProps {
  annotation: IAnnotation;
  typeId: number;
  updateAnnotation: (args: {
    id: number;
    action: (typeof annotationActions)[keyof typeof annotationActions];
    severity?: (typeof severities)[keyof typeof severities];
  }) => void;
  isDefect: boolean;
}
export default function AnnotationReviewButtons(props: IProps) {
  const { annotation, typeId, updateAnnotation, isDefect } = props;

  const typeIdIndex = annotation.type_id.findIndex((id) => id === typeId);

  const _updateAnnotation = (args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
    severity?: (typeof severities)[keyof typeof severities];
  }) =>
    updateAnnotation({
      id: typeId,
      ...args,
    });

  return (
    <>
      <div className="supervisorButtons">
        <ApproveSelector
          workflowStatus={annotation.workflow_status[typeIdIndex]}
          skyqraft_hidden={annotation.skyqraft_hidden[typeIdIndex]}
          updateAnnotation={_updateAnnotation}
          isDefect={isDefect}
        />
        <HideSelector
          workflowStatus={annotation.workflow_status[typeIdIndex]}
          skyqraft_hidden={annotation.skyqraft_hidden[typeIdIndex]}
          updateAnnotation={_updateAnnotation}
          isDefect={isDefect}
        />
        <RejectSelector
          workflowStatus={annotation.workflow_status[typeIdIndex]}
          updateAnnotation={_updateAnnotation}
        />
      </div>
      <p style={{ margin: 0, paddingLeft: 5 }}>
        {annotation.confidence[typeIdIndex]}
      </p>
    </>
  );
}
