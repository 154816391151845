import styled from "styled-components";

/*
	Define all the base constants for the arrow
	These make it possible to resize the arrow
	without breaking the UI
*/
const ARROW_COLOR = "red";
const ARROW_SIZE = 3; // This is the base size to start from
const arrow_body_width = ARROW_SIZE;
// The ratio between arrow body width and height
const arrow_body_height = ARROW_SIZE * 4;
// The ratio between arrow body and arrow head (triangle) width
const arrow_head_width = arrow_body_width * 2;
// The ratio between arrow body and arrow head (triangle) height
// the higher the multipler, the pointier the arrow
const arrow_head_height = arrow_head_width * 2;
// The total width and height of the arrow, to manage paddings
const arrow_total_width = arrow_head_width * 4;
const arrow_total_height = arrow_body_height + arrow_head_height;

// Adapt the container based on where it is placed
const ArrowContainer = styled.div`
	position: absolute;
	top: ${(props) => (props.onMap ? "undefined" : "0px")};
	bottom: ${(props) => (!props.onMap ? "undefined" : "0px")};
	left: 0px;
	transform: ${(props) =>
    props.onMap ? "translate( -50%, 50% )" : "undefined"};
	z-index: 1;
	opacity: 0.55;

`;

// Resizable arrow component
const Arrow = styled.div`
	width: ${(props) => arrow_total_width * props.sizeMultiplier}px;
	height: ${(props) => arrow_total_height * props.sizeMultiplier}px;
	display: flex;
	flex-direction: column;
	align-items: center;
	rotate: ${(props) => props.angle}deg;
`;
const ArrowHead = styled.div`
	border-left: ${(props) => arrow_head_width * props.sizeMultiplier}px solid transparent;
	border-right: ${(props) => arrow_head_width * props.sizeMultiplier}px solid transparent;
	border-bottom: ${(props) =>
    arrow_head_height * props.sizeMultiplier}px solid ${ARROW_COLOR};
`;
const ArrowBody = styled.div`
	width: ${(props) => arrow_body_width * props.sizeMultiplier}px;
	height: ${(props) => arrow_body_height * props.sizeMultiplier}px;
	background-color: ${ARROW_COLOR};
`;

export interface IProps {
  angle: number;
  onMap?: boolean;
}

export default function FlowDirectionArrow({ angle, onMap }: IProps) {
  if (!angle) {
    return null;
  }
  const sizeMultipler = onMap ? 4 : 1;
  return (
    <ArrowContainer onMap={onMap}>
      <Arrow angle={angle} sizeMultiplier={sizeMultipler}>
        <ArrowHead sizeMultiplier={sizeMultipler} />
        <ArrowBody sizeMultiplier={sizeMultipler} />
      </Arrow>
    </ArrowContainer>
  );
}
