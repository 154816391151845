import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MotionPhotosOffOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ObjectType, IssueSeverity, IssueCategory } from "interfaces";
type Props = {
  objectType: ObjectType;
  regionID: number;
  workflowStatus: number;
  setMLReview: (
    image: number,
    regionID: number,
    regionType: number,
    action: string,
    severity_id?
  ) => void;
  issueSeverities: IssueSeverity[];
  skyqraft_hidden: boolean;
  issueCategories: IssueCategory[];
};

export default function HideSelector({
  objectType,
  regionID,
  issueSeverities,
  workflowStatus,
  setMLReview,
  skyqraft_hidden,
  issueCategories,
}: Props) {
  const params = useParams();
  const image = parseInt(params.image);
  let issueCatIds = issueCategories.map((e) => e.id);
  const isIssue = issueCatIds.includes(objectType?.category);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          if (workflowStatus === 2 && skyqraft_hidden) {
            setMLReview(image, regionID, objectType.id, "request");
          } else if (!isIssue) {
            setMLReview(image, regionID, objectType.id, "approve-hide");
          } else if (isIssue) handleClick(e);
        }}
      >
        <MotionPhotosOffOutlined
          htmlColor={
            workflowStatus === 2 && skyqraft_hidden ? "orange" : "white"
          }
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {issueSeverities
          .filter((i) => i.id < 10)
          .map((severity) => (
            <MenuItem
              key={severity.id}
              onClick={() => {
                setMLReview(
                  image,
                  regionID,
                  objectType.id,
                  "approve-hide",
                  severity.id
                );
                handleClose();
              }}
            >
              {severity.en_name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
