import { connect } from "react-redux";
import Component from "./RejectSelector";
import { RootState } from "state/store";
import { setMLReview } from "state/actions";
import { ObjectType } from "interfaces";

interface IDispatchProps {
  setMLReview: (
    image: number,
    regionID: number,
    regionType: number,
    action: string,
    severity_id?
  ) => void;
}

interface IStateProps {}

interface IOwnProps {
  objectType: ObjectType;
  regionID: number;
  workflowStatus: number;
}

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => {
  return {};
};

const mapDispatchToProps = {
  setMLReview,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
