import { ReactElement, useEffect, useState, useRef } from "react";
import colormap from "colormap";
import { useSearchParams } from "react-router-dom";
import "./style.scss";

interface Props {
  minTemp: number;
  maxTemp: number;
}

export default function Slider({ minTemp, maxTemp }: Props): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const low = searchParams.has("low")
    ? parseFloat(searchParams.get("low"))
    : minTemp;
  const high = searchParams.has("high")
    ? parseFloat(searchParams.get("high"))
    : maxTemp;

  const [moving, setMoving] = useState(null);

  useEffect(() => {
    if (!!maxTemp && !!minTemp) {
      if (low < minTemp || low > maxTemp) {
        searchParams.set("low", minTemp.toFixed(3));
        setSearchParams(searchParams.toString(), { replace: true });
      }
      if (high < minTemp || high > maxTemp) {
        searchParams.set("high", maxTemp.toFixed(3));
        setSearchParams(searchParams.toString(), { replace: true });
      }
    }
  }, [low, high, minTemp, maxTemp, searchParams, setSearchParams]);

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  });

  function onMouseDown(e) {
    if (e.target.classList.value === "colorIndicator" && !moving) {
      setMoving(e.target.id);
    } else {
      setMoving(null);
    }
  }

  function onMouseMove(e) {
    if (!!moving) {
      const total_range = maxTemp - minTemp;
      const colorBBox = colorRef.current.getBoundingClientRect();
      const percentagePress = 1 - (e.pageY - colorBBox.y) / colorBBox.height;
      switch (moving) {
        case "upper":
          searchParams.set(
            "high",
            Math.max(
              Math.min(maxTemp, minTemp + total_range * percentagePress),
              low
            ).toFixed(3)
          );
          setSearchParams(searchParams.toString(), { replace: true });

          break;
        case "lower":
          searchParams.set(
            "low",
            Math.min(
              Math.max(minTemp, minTemp + total_range * percentagePress),
              high
            ).toFixed(3)
          );
          setSearchParams(searchParams.toString(), { replace: true });
          break;
      }
    }
  }

  function onMouseUp(e) {
    !!moving && setMoving(null);
  }

  const colors = colormap({
    colormap: "temperature",
    nshades: 50,
    format: "rba",
    alpha: 1,
  });
  colors.reverse();

  const colorRef = useRef(null);
  const defaultHeight = 1 / colors.length;
  const lowHeight = (low - minTemp) / (maxTemp - minTemp);
  const highHeight = (maxTemp - high) / (maxTemp - minTemp);
  let remainingHeight = 1;
  let nLongs = 0;
  if (lowHeight > defaultHeight) {
    remainingHeight -= lowHeight;
    nLongs++;
  }
  if (highHeight > defaultHeight) {
    remainingHeight -= highHeight;
    nLongs++;
  }

  return (
    <div className="noselect" id="temperatureSlider">
      <div className="noselect" id="colors" ref={colorRef}>
        {colors.map((color, index) => {
          let height = defaultHeight;
          if (index === 0 && highHeight > defaultHeight) {
            height = highHeight;
          } else if (index === colors.length - 1 && lowHeight > defaultHeight) {
            height = lowHeight;
          } else {
            height = remainingHeight / (colors.length - nLongs);
          }
          return (
            <div
              key={index}
              className="noselect"
              style={{
                backgroundColor: `rgba(${color[0]},${color[1]},${color[2]},${color[3]})`,
                width: "100%",
                height: `${100 * height}%`,
              }}
            />
          );
        })}
      </div>
      <div id="rangeSelector">
        <div className="relativor" style={{ pointerEvents: "none" }}>
          <div
            id="top"
            style={{
              bottom: `${(100 * (high - minTemp)) / (maxTemp - minTemp)}%`,
              pointerEvents: "none",
            }}
          >
            <div
              className="colorIndicator"
              id="upper"
              style={{ pointerEvents: "all" }}
            />
            <p className="noselect">{high.toFixed(2)} °C</p>
          </div>
          <div
            id="bottom"
            style={{
              top: `${(100 * (maxTemp - low)) / (maxTemp - minTemp)}%`,
              pointerEvents: "none",
            }}
          >
            <div
              className="colorIndicator"
              id="lower"
              style={{ pointerEvents: "all" }}
            />
            <p className="noselect">{low.toFixed(2)} °C</p>
          </div>
        </div>
      </div>
    </div>
  );
}
